<template>
    <div class="px-3 py-2" style="direction:rtl">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="100vw" no-header id="delete_order" backdrop style="direction:ltr" right title="add order" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{tablename}}</span>
                    </div>
                    <div @click="hide" id="vohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <div style="margin-left:25px;margin-right:25px;">
                    <v-row style="direction:rtl">
                        <v-col cols="6">
                            <v-card
                                    class="mx-auto"
                                    max-width="344"
                                    min-width="250"
                                    outlined
                                >
                                 <v-list-item >
                                     <v-list-item-title class="text-h6 mb-1" style="">
                                       {{lang.full_name}}: {{customer.full_name}}
                                    </v-list-item-title>
                                 </v-list-item>
                                 <v-list-item >
                                     <v-list-item-title class="text-h6 mb-1" style="">
                                        {{lang.mobile}}: {{customer.mobile}}
                                    </v-list-item-title>
                                 </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <vue-snotify></vue-snotify>
                </div>
                </template>
               
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
// import posDiscount from '@/components/pos_discount.vue';
// import PosNotes from '@/components/pos-notes.vue';
// import PosAddinvoice from '@/components/pos-addinvoice.vue';

export default{
//   components: { posDiscount, PosNotes, PosAddinvoice },
    name: 'POS',
    data() {
        return {
            tableid: 0,
            tablename: '',
            orderid: 0,
            selectedIndex: -1,
            selectedItem: {},
            categories: [],
            subcats:[],
            itemrows: [],
            allitems: [],
            customer:{
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            },
            company:{
                fullname: '',
                vatid: '',
            },
            discount: 0,
            notes: '',
            paytype: 1,
            invtype: 1,
            paytypes: [],
            paidamount: 0
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        invtypes: function(){
            return [
                {
                    text: this.lang.none_paid_invoice,
                    value: 2,
                },
                {
                    text: this.lang.paid_invoice,
                    value: 1,
                },
            ]
        },
        curency: function(){
            return this.lang.curency;
        },
        total_after_discount: function(){
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.intotal) - parseFloat(this.discount);
            return this.$RoundNums(t,3);
        },
        intotal: function(){
            let t = 0;
            for(let i=0;i<this.itemrows.length;i++){
                t = parseFloat(t) + parseFloat(this.itemrows[i].total);
            }
            return this.$RoundNums(t,3);
        },
        invat: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].vat);
            // }
            
        //    for(let i=0;i<this.itemrows.length;i++){
        //         t = parseFloat(t) + parseFloat(this.itemrows[i].vat) + parseFloat(this.itemrows[i].smoke);
        //     }
            t = parseFloat(this.total_after_discount) * parseFloat(115/100)
            return this.$RoundNums(t,3);
        },
        inftotal: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].ftotal);
            // }
            // t = parseFloat(t) * parseFloat(this.discountper)
            t = parseFloat(this.total_after_discount) + this.invat;
            return this.$RoundNums(t,3);
        },
        discountper: function() {
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.discount) / parseFloat(this.intotal);
            
            return this.$RoundNums(t,3);
        }
    },
    methods: {
        changeColor(index){
            for(let i=0;i<this.categories.length;i++){
                if(index == i){
                    document.getElementById('cats_'+i).classList.add("btn-balck");
                    document.getElementById('cats_'+i).classList.remove("btn-yallow");
                }else{
                    document.getElementById('cats_'+i).classList.add("btn-yallow");
                    document.getElementById('cats_'+i).classList.remove("btn-balck");
                }
            }
        },
        selectThie(item,index){
           if(this.selectedIndex != -1){
               if(this.selectedIndex != index){
                   document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                   this.selectedIndex = index;
                    this.selectedItem = item;
                    document.getElementById('item_'+index).style.background = '#ccc';
                    return false;
               }
               this.selectedIndex = -1;
               this.selectedItem = {};
               document.getElementById('item_'+index).style.background = '#FFF';
           }else{
                this.selectedIndex = index;
                this.selectedItem = item;
                document.getElementById('item_'+index).style.background = '#ccc';
               
           }
        },
        delSel(){
            if(this.selectedIndex != -1){
                this.itemrows.splice(this.selectedIndex,1);
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        restData(){
            this.tableid =  0;
            this.tablename =  '';
            this.orderid =  0;
            this.customer = {
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            },
            this.company = {
                fullname: '',
                vatid: '',
            },
            this.discount =  0;
            this.notes =  '';
            this.paytype =  1;
            this.invtype =  1;
        },
        getName(){
            // console.log(this.customer);
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                    }
                }
            )
        },
        getCats(item){
            if(item.type != 0 && item.id != 0){
                this.getProducts(item.id);
                return false;
            }
            const post = new FormData();
            post.append('type', 'getPCats');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            post.append('data[type]',item.type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
               
                if(item.id == 0){
                    this.categories = res.results.data;
                }else{
                    this.allitems = [];
                    this.subcats = res.results.data;
                }
            })
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print-80m.php?invid='+id;
        },
    },
    created() {
        // this.getCats({id:0,type:0});
    },
}
</script>

<style>
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 50px);
    min-height:calc(100vh - 50px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 80px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:65px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#ccc;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    background:#222;
    color:#fff;
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#6c757d;
    color: #fff;
    border-radius: 7px;
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-balck,.btn-balck:active,.btn-balck:hover,.btn-balck:visited{
    background: #000 !important;
    color:#fff !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
</style>